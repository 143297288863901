import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashBoard from './farm/pages/DashBoard';
import PredictiveAnalytics from './farm/pages/PredictiveAnalytics';
import Settings from './farm/pages/Settings';
import DashboardH from './household/pages/DashboardH';
import HistoryH from './household/pages/HistoryH';
import SavingsGoalsH from './household/pages/SavingsGoalsH';
import SettingsH from './household/pages/SettingsH';
import Market from './farm/pages/Market';
import History from './farm/pages/History';
import DAO from './farm/pages/DAO';

// There are 2 types of users: farm and household

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<DashBoard />} />
          <Route path="/market" element={<Market />} />
          <Route path="/history" element={<History />} />

          <Route path="/dao" element={<DAO />} />
          <Route path="/predictive-analytics" element={<PredictiveAnalytics />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/household" element={<DashboardH />} />
          <Route path="/household/history" element={<HistoryH />} />
          <Route path="/savings-goalsH" element={<SavingsGoalsH />} />
          <Route path="/settingsH" element={<SettingsH />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

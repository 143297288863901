import React from 'react';
import styled from 'styled-components';

// Dummy Data
const transactions = [
    // Sold energy transactions - adjusting values to reflect $0.15 per kWh
    { date: '2024-02-10', type: 'sold energy', amount: '1000 kWh', value: '150 USDC' },
    { date: '2024-02-09', type: 'sold energy', amount: '750 kWh', value: '112.5 USDC' },
    { date: '2024-02-08', type: 'sold energy', amount: '1200 kWh', value: '180 USDC' },
    { date: '2024-02-07', type: 'sold energy', amount: '500 kWh', value: '75 USDC' },
    // Sold and bought shares transactions
    { date: '2024-02-06', type: 'sold shares', amount: '2000 shares', value: '2400 USDC' },
    { date: '2024-02-05', type: 'bought shares', amount: '1000 shares', value: '1200 USDC' },
    // Signatures indicating operational or agreement confirmations
    { date: '2024-02-04', type: 'signature', amount: 'N/A', value: 'Confirmed' },
    { date: '2024-02-03', type: 'signature', amount: 'N/A', value: 'Confirmed' },
    // More energy sales reflecting ongoing operations
    { date: '2024-02-02', type: 'sold energy', amount: '850 kWh', value: '127.5 USDC' },
    { date: '2024-02-01', type: 'sold energy', amount: '950 kWh', value: '142.5 USDC' },
    { date: '2024-02-10', type: 'sold energy', amount: '1000 kWh', value: '150 USDC' },
    { date: '2024-02-09', type: 'sold energy', amount: '750 kWh', value: '112.5 USDC' },
    { date: '2024-02-08', type: 'sold energy', amount: '1200 kWh', value: '180 USDC' },
    { date: '2024-02-07', type: 'sold energy', amount: '500 kWh', value: '75 USDC' },
    // Sold and bought shares transactions
    { date: '2024-02-06', type: 'sold shares', amount: '2000 shares', value: '2400 USDC' },
    { date: '2024-02-05', type: 'bought shares', amount: '1000 shares', value: '1200 USDC' },
    // Signatures indicating operational or agreement confirmations
    { date: '2024-02-04', type: 'signature', amount: 'N/A', value: 'Confirmed' },
    { date: '2024-02-03', type: 'signature', amount: 'N/A', value: 'Confirmed' },
    // More energy sales reflecting ongoing operations
    { date: '2024-02-02', type: 'sold energy', amount: '850 kWh', value: '127.5 USDC' },
    { date: '2024-02-01', type: 'sold energy', amount: '950 kWh', value: '142.5 USDC' },
    { date: '2024-02-10', type: 'sold energy', amount: '1000 kWh', value: '150 USDC' },
    { date: '2024-02-09', type: 'sold energy', amount: '750 kWh', value: '112.5 USDC' },
    { date: '2024-02-08', type: 'sold energy', amount: '1200 kWh', value: '180 USDC' },
    { date: '2024-02-07', type: 'sold energy', amount: '500 kWh', value: '75 USDC' },
    // Sold and bought shares transactions
    { date: '2024-02-06', type: 'sold shares', amount: '2000 shares', value: '2400 USDC' },
    { date: '2024-02-05', type: 'bought shares', amount: '1000 shares', value: '1200 USDC' },
    // Signatures indicating operational or agreement confirmations
    { date: '2024-02-04', type: 'signature', amount: 'N/A', value: 'Confirmed' },
    { date: '2024-02-03', type: 'signature', amount: 'N/A', value: 'Confirmed' },
    // More energy sales reflecting ongoing operations
    { date: '2024-02-02', type: 'sold energy', amount: '850 kWh', value: '127.5 USDC' },
    { date: '2024-02-01', type: 'sold energy', amount: '950 kWh', value: '142.5 USDC' },
    { date: '2024-02-10', type: 'sold energy', amount: '1000 kWh', value: '150 USDC' },
    { date: '2024-02-09', type: 'sold energy', amount: '750 kWh', value: '112.5 USDC' },
    { date: '2024-02-08', type: 'sold energy', amount: '1200 kWh', value: '180 USDC' },
    { date: '2024-02-07', type: 'sold energy', amount: '500 kWh', value: '75 USDC' },
    // Sold and bought shares transactions
    { date: '2024-02-06', type: 'sold shares', amount: '2000 shares', value: '2400 USDC' },
    { date: '2024-02-05', type: 'bought shares', amount: '1000 shares', value: '1200 USDC' },
    // Signatures indicating operational or agreement confirmations
    { date: '2024-02-04', type: 'signature', amount: 'N/A', value: 'Confirmed' },
    { date: '2024-02-03', type: 'signature', amount: 'N/A', value: 'Confirmed' },
    // More energy sales reflecting ongoing operations
    { date: '2024-02-02', type: 'sold energy', amount: '850 kWh', value: '127.5 USDC' },
    { date: '2024-02-01', type: 'sold energy', amount: '950 kWh', value: '142.5 USDC' },
    { date: '2024-02-10', type: 'sold energy', amount: '1000 kWh', value: '150 USDC' },
    { date: '2024-02-09', type: 'sold energy', amount: '750 kWh', value: '112.5 USDC' },
    { date: '2024-02-08', type: 'sold energy', amount: '1200 kWh', value: '180 USDC' },
    { date: '2024-02-07', type: 'sold energy', amount: '500 kWh', value: '75 USDC' },
    // Sold and bought shares transactions
    { date: '2024-02-06', type: 'sold shares', amount: '2000 shares', value: '2400 USDC' },
    { date: '2024-02-05', type: 'bought shares', amount: '1000 shares', value: '1200 USDC' },
    // Signatures indicating operational or agreement confirmations
    { date: '2024-02-04', type: 'signature', amount: 'N/A', value: 'Confirmed' },
    { date: '2024-02-03', type: 'signature', amount: 'N/A', value: 'Confirmed' },
    // More energy sales reflecting ongoing operations
    { date: '2024-02-02', type: 'sold energy', amount: '850 kWh', value: '127.5 USDC' },
    { date: '2024-02-01', type: 'sold energy', amount: '950 kWh', value: '142.5 USDC' },
  ];
  

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 25px;
    width: 100%;
  margin-left: 20%;
  font-family: 'Outfit', sans-serif;
`;

const Table = styled.table`
//   margin-top: 20px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
//   border: 2px solid #000;
  padding-bottom: 10px;
  overflow: hidden; // Ensures the rounded corners are visible
`;

const H1 = styled.h1`
    font-size: 1.4em;
    font-weight: 400;
    color: #3c4c4b;
    margin:0;

    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
`;


const TableRow = styled.tr``;

const TableHeader = styled.th`
//   border: 1px solid #ddd;
// border: 2px solid #000;
border: none;
font-weight: 300;
font-size: 3em ;

  padding: 18px;
  text-align: left;
//   background-color: #3c4c4b;;
  color: black;
`;

const TableCell = styled.td`
//   border: 1px solid #ddd;
// border-bottom: 2px solid #000;
  padding: 8px;
padding-left: 25px;
font-size: 1em;
`;

const TypeIndicator = styled.span`
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  color: white;
  background-color: ${props => props.type === 'sold energy' ? '#f9a825' :
                    props.type === 'sold shares' ? '#c62828' :
                    props.type === 'bought shares' ? '#2e7d32' : '#1565c0'};
`;

const MainHistory = () => {
  return (
    <Container>
        <H1>Transaction History</H1>
      <Table>
        <thead>
          <tr>
            <TableHeader>Date</TableHeader>
            <TableHeader>Type</TableHeader>
            <TableHeader>Amount</TableHeader>
            <TableHeader>Value</TableHeader>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell>{transaction.date}</TableCell>
              <TableCell>
                <TypeIndicator type={transaction.type}>
                  {transaction.type}
                </TypeIndicator>
              </TableCell>
              <TableCell>{transaction.amount}</TableCell>
              <TableCell>{transaction.value}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default MainHistory;

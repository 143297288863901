import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    // background-color: #f3d4bd;
    padding: 20px;
    //  limit width to as much as the content
    max-width: fit-content;
    border-radius: 10px;
    `

    const FarmName = styled.h1`
    font-size: 1.3em;
    font-weight: 400;
    margin:0;
    margin-bottom: 20px;
    padding: 0;
    `

    const FarmId = styled.h2`
    font-size: 1em;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    `

    const FarmLocation = styled.h2`
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
    padding: 0;
    `

    const FarmCapacity = styled.h2`
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
    margin-left: 20px;
    `

    const Flexer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    `


const FarmProfileSummary = () => {
  return (
    <Container>
      {/* <FarmName>Delta Meadows Renewable Energy Farm</FarmName>
      <FarmId>FARM_000</FarmId>
      <FarmLocation>Location: Sacramento, CA</FarmLocation>
      <FarmCapacity>Capacity: 1000 MW</FarmCapacity> */}
        {/* <Flexer> */}
            <FarmName>Delta Meadows Renewable Energy Farm</FarmName>
            <FarmId>FARM_000</FarmId>   
        {/* </Flexer> */}
        <Flexer>
            <FarmLocation>Location: Sacramento, CA</FarmLocation>
            <FarmCapacity>Capacity: 32 MW</FarmCapacity>
        </Flexer>
    </Container>
  )
}

export default FarmProfileSummary

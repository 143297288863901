import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { db } from '../../Firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import styled, {keyframes} from 'styled-components';

// Styled components for visual design
const waveAnimation = keyframes`
0%, 100% {
  border-radius: 10px 15px 10px 15px;
}
25% {
  border-radius: 15px 10px 15px 10px;
}
50% {
  border-radius: 10px 5px 10px 5px;
}
75% {
  border-radius: 5px 10px 5px 10px;
}
`;

const ChartContainer = styled.div`
  // background-color: #f3d4bd;
  // border: 2.2px solid #3c4c4b;
  border-radius: 8px;
  margin-right: 20px;
  // box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  position: relative; /* Allows for absolute positioning of children */

  &:hover {
    // box-shadow: 0 4px 6px rgba(0,0,0,0.2);
    // make the background color darker in the same color family as f3d4bd yet darker
    // background-color:#FDEBDD;
    animation: ${waveAnimation} 1.5s infinite linear;
    h3 {
      // color: white;
    }

    p {
      // color: white;
    }
      
  }

  &:hover{
    // background-color: #FDEBDD;
//   
  }
`;

const Title = styled.h3`
  font-size: 25px;
  font-weight: 400;
  margin:0;
  font-size: 1.2em;
  margin-bottom: 20px;
  margin-top:20px;
  color: #333; /* Changed to darker color for visibility */
`;

const StyledSvg = styled.svg`
  width:90%;
  height: 400px;
  overflow: visible;
  padding: 20px;
  border-radius: 10px;

    &:hover {
        // background-color: #ededed;
    }
`;

const AxisLabel = styled.div`
  position: absolute;
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
`;

const XAxisLabels = styled(AxisLabel)`
  bottom: 0;
  left: 24%;
  padding: 12px; /* Adjust based on bottom margin and axis label heights */
  width: 100%;
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  text-align: center;
`;

const YAxisLabels = styled(AxisLabel)`
  top: 36%;
  left: 0;
  margin-left: 60px; /* Adjust based on left margin */
  bottom: 40px; /* Adjust based on bottom margin and axis label heights */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  writing-mode: vertical-lr; /* Makes text go vertical, use 'vertical-rl' for reverse direction */
  transform: translateX(-100%); /* Adjust as necessary to align properly */
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  font-size: 18px;

`;


// const YAxisLabels = styled(AxisLabel)`
//   top: 50%;
//   transform: translateX(-100%) translateY(-50%) rotate(-90deg);
//   transform-origin: left;
//   left: 0;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   white-space: nowrap; /* Prevent text from wrapping */
// `;
// fix:

// const YAxisLabels = styled(AxisLabel)`
//   // top: 50%;
//   transform: translateX(-100%) translateY(-50%) rotate(-90deg);
//   transform-origin: left;
//   left: 0;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   white-space: nowrap; /* Prevent text from wrapping */
// `;


const DailyProductionChart = () => {
  const chartRef = useRef(null);

  const drawChart = async () => {
    const q = query(collection(db, "farmData"), where("farm_id", "==", "FARM000"));
    const querySnapshot = await getDocs(q);
    let initialTimestamp = new Date("2024-01-01T08:00:00");
    const data = [];

    // querySnapshot.docs.forEach((doc, index) => {
    //   const daysToAdd = Math.floor(index / 50);
    //   let timestamp = new Date(initialTimestamp.getTime() + daysToAdd * 24 * 60 * 60000 + (index % 50) * 15 * 60000);
    //   data.push({...doc.data(), timestamp: timestamp.getTime()});
    //   if ((index + 1) % 50 === 0 && index !== querySnapshot.docs.length - 1) {
    //     data.push({energy_produced_kWh: 100, timestamp: timestamp.getTime() + 15 * 60000});
    //   }
    // });

    querySnapshot.docs.forEach((doc, index) => {
      const daysToAdd = Math.floor(index / 50);
      let timestamp = new Date(initialTimestamp.getTime() + daysToAdd * 24 * 60 * 60000 + (index % 50) * 15 * 60000);
    
      // Check if it's the first data point of the day
      if (index % 50 === 0) {
        // data.push({energy_produced_kWh: 100, timestamp: timestamp.getTime()});
      } else if ((index + 1) % 50 === 0) { // Check if it's the last data point of the day
        // data.push({...doc.data(), energy_produced_kWh: 100, timestamp: timestamp.getTime()});
        // Insert a null data point for the gap if needed, adjust as per your requirements
        // if (index !== querySnapshot.docs.length - 1) {
        //   data.push({energy_produced_kWh: null, timestamp: timestamp.getTime() + 15 * 60000});
        // }
      } else { // For all other data points
        data.push({...doc.data(), timestamp: timestamp.getTime()});
      }
    });
    

    const filteredData = data.filter(d => {
      const date = new Date(d.timestamp);
      return date.getHours() >= 6 && date.getHours() <= 18; // Keeps data points between 6 AM and 6 PM
    });

    if (chartRef.current) {
        const margin = { top: 30, right: 55, bottom: 10, left: 10 };
        const svg = d3.select(chartRef.current)
                      .append('g')
                      .attr('transform', `translate(${margin.left},${margin.top})`);
  
        const width = svg.node().parentNode.getBoundingClientRect().width - margin.left - margin.right;
        const height = 400 - margin.top - margin.bottom;
  
        const xScale = d3.scaleTime()
          .domain(d3.extent(filteredData.slice(-50), d => new Date(d.timestamp))) // Only the last 250 entries
          .range([0, width]);
  
        const yScale = d3.scaleLinear()
          .domain([0, d3.max(filteredData.slice(-50), d => d.energy_produced_kWh)]) // Only the last 250 entries
          .range([height, 0]);
  
        const lineGenerator = d3.line()
          .defined(d => d.energy_produced_kWh !== null)
          .x(d => xScale(new Date(d.timestamp)))
          .y(d => yScale(d.energy_produced_kWh));
  
        svg.append("path")
           .datum(filteredData.slice(-50).filter(d => d.energy_produced_kWh !== null)) // Only the last 250 entries
           .attr("fill", "none")
           .attr("stroke", "#ff5722")
           .attr("stroke-width", 2)
           .attr("d", lineGenerator);
      }
    };
  
    useEffect(() => {
      drawChart();
    }, []);

  return (
    <ChartContainer>
      <Title>Energy Produced (kWh) - Last 24 hrs</Title>
      <StyledSvg ref={chartRef} />
      {/* Positioning for X and Y axis labels should be adjusted based on actual axis scale values */}
      <XAxisLabels>
        {/* X-axis labels here, spaced with flex */}
        {/* <p>15 minute intervals <br />(energy produced between 8AM-3PM)</p> */}
      </XAxisLabels>
      <YAxisLabels>
        {/* Y-axis labels here, spaced with flex-direction: column */}
       {/* <p> Energy produced (kWh)</p> */}
      </YAxisLabels>
    </ChartContainer>
  );
};

export default DailyProductionChart;
import React from 'react'
import styled from 'styled-components'
import intensity from '../../assets/intensity.png'
import weather from '../../assets/weather.png'
import history from '../../assets/history.png'

const EnergyForecastContainer = styled.div`
    display: flex;
    flex-direction: column;
    `

    const EnergyForecastRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    // create animation for hover
    animation: ease-in-out;

    &:hover {
        background-color: #f2f2f2;
    }

    `

    const Image = styled.img`
    width: 50px;
    height: auto;
    `

    const Text = styled.div`
    font-size: 1.2em;
    padding-left: 20px;
    `



    const Head = styled.h1`
    font-size: 1.2em;
    font-weight: 400;
    margin-bottom: 10px;
    color: #3c4c4b;
    `



const EnergyPredictionFactors = () => {
  return (
    <EnergyForecastContainer>
        <Head>Factors Affecting Energy Production</Head>
        <EnergyForecastRow>
            <Image src={intensity} alt="intensity" />
            <Text>Solar Intensity</Text>
        </EnergyForecastRow>
        <EnergyForecastRow>
            <Image src={weather} alt="weather" />
            <Text>Weather</Text>
        </EnergyForecastRow>
        <EnergyForecastRow>
            <Image src={history} alt="history" />
            <Text>Historical Trends</Text>
        </EnergyForecastRow>
    </EnergyForecastContainer>
  )
}

export default EnergyPredictionFactors

import React from 'react'
import SideNav from '../components/SideNav'
import MainDash from '../components/MainDash'
import styled from 'styled-components'

const DashBoardContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
    // width: 100px;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;

background: #F9F5F5;
    `


const DashBoardH = () => {
  return (
    <DashBoardContainer>
        <SideNav />
        <MainDash />
    </DashBoardContainer>
  )
}

export default DashBoardH

import React from 'react'
import styled from 'styled-components'

const StockPriceDisplayContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // max-width: fit-content;
    // margin: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    font-family: 'Outfit', sans-serif;
    // background-color: #f3d4bd;
    padding: 20px;
    border-radius: 10px;
`

const StockPrice = styled.h1`
    font-size: 4em;
    font-weight: 300;
    margin:0;
    margin-bottom: 10px;
    padding: 0;
    `

    const Desc = styled.h2`
    font-size: 1em;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    `

const StockPriceDisplay = () => {
  return (
    <StockPriceDisplayContainer>
      <StockPrice>$ 1.2</StockPrice>
      <Desc>Stock Price</Desc>
    </StockPriceDisplayContainer>
  )
}

export default StockPriceDisplay

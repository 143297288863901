import React, { useState } from 'react';
import styled from 'styled-components';
import Sun from '../../assets/sun.png';

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 70px;
`;

const ProgressBar = styled.div`
  width: 20px;
  height: 300px;
  border: 2px solid #3c4c4b;
  background: #e6e6e6;
  position: relative;
`;

const Progress = styled.div`
  width: 100%;
  height: ${(props) => props.value * (300 / 12)}px;
  position: absolute;
  bottom: 0;
  background: #f8d64e;
`;

const Slider = styled.input.attrs({
  type: 'range',
  min: '0',
  max: '12',
  orient: 'vertical',
})`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background: transparent;
  margin: 0 10px;
  transform: rotate(270deg);

  &::-webkit-slider-runnable-track,
  &::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: #ddd;
    border-radius: 5px;
  }

  &::-webkit-slider-thumb,
  &::-moz-range-thumb {
    -webkit-appearance: none;
    width: 50px;
    height: 50px;
    background: url(${Sun}) no-repeat center center;
    background-size: cover;
    cursor: pointer;
    margin-top: -24px;
  }
`;

const SliderLabel = styled.div`
  margin-bottom: 10px;
  font-size: 1.4rem;
  color: #3c4c4b;
  font-family: 'Outfit', 'Noto Sans', sans-serif;
  padding: 20px;
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const SunSlider = ({ onSunlightChange }) => {
  const [sunlightHours, setSunlightHours] = useState(6);

  const handleChange = (e) => {
    const newSunlightHours = e.target.value;
    setSunlightHours(newSunlightHours);
    if (onSunlightChange) {
      onSunlightChange(newSunlightHours);
    }
  };

  return (
    <SliderContainer>
      <SliderLabel>{sunlightHours} hours of sunlight</SliderLabel>
      <SliderWrapper>
        <Slider value={sunlightHours} onChange={handleChange} />
        <ProgressBar>
          <Progress value={sunlightHours} />
        </ProgressBar>
      </SliderWrapper>
    </SliderContainer>
  );
};

export default SunSlider;

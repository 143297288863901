import React from 'react'
import SideNav from '../components/SideNav'
import MainMarket from '../components/MainMarket'

const Market = () => {
  return (
    <div>
      <SideNav />
      <MainMarket />
    </div>
  )
}

export default Market

import React from 'react';
import notificationImage from '../../assets/notification.png';
import styled, { keyframes } from 'styled-components';

const waveAnimation = keyframes`
  0%, 100% {
    border-radius: 10px 15px 10px 15px;
  }
  25% {
    border-radius: 15px 10px 15px 10px;
  }
  50% {
    border-radius: 10px 5px 10px 5px;
  }
  75% {
    border-radius: 5px 10px 5px 10px;
  }
`;

const NotificationButtonContainer = styled.div`
  position: fixed;
  top: 10px;
  right: 0;
  margin: 10px;
  z-index: 100;
`;

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: #f3d4bd;
  padding: 16px;
  transition: 0.3s;
  &:hover {
    background-color: #ebc4a7;
    // animation: ${waveAnimation} 1.5s infinite linear;
  }
`;

const Img = styled.img`
  width: 20px;
  height: auto;
`;

const NotificationButton = () => {
  return (
    <NotificationButtonContainer>
      <Button>
        <Img src={notificationImage} alt="notification" />
      </Button>
    </NotificationButtonContainer>
  );
};

export default NotificationButton;

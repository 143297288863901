import React from 'react'
import styled from 'styled-components' 

// Styled component for the Farm Info

const FarmInfoBox = styled.div`
display: flex;
flex-direction: column;
// background-color: #f0f0f0;
// justify-content: center;
// align-items: center;
// height: 10vh;
// margin: 10px;
// padding: 20px;
margin-left: 30px;
// margin-top: 50px;
`

const Heading = styled.h1`
  font-size: 1.6em;
  font-weight: 400;
  margin-bottom: 10px;
  color: #3c4c4b;

`

const Subheading = styled.h2`
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  padding: 0;

`


const HouseInfo = () => {
  return (
    <FarmInfoBox>
      <Heading>The Anderson Estates</Heading>
      <Subheading>METER_000</Subheading>
    </FarmInfoBox>
  )
}

export default HouseInfo

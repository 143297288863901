import React from 'react'
import styled from 'styled-components'

const EnergyForecastContainer = styled.div`
    display: flex;
    flex-direction: row;
    `



const AIRecommendations = () => {
  return (
    <EnergyForecastContainer>
        AIRecommendations
    </EnergyForecastContainer>
  )
}

export default AIRecommendations

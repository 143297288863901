import React from 'react'
import styled from 'styled-components'  
import EnergyForecast from './EnergyForecast'
import DemandForecast from './DemandForecast'
import MarketTrends from './MarketTrends'
import RevenueOpportunities from './RevenueOpportunities'
import AIRecommendations from './AIRecommendations'
import InteractiveModelling from './InteractiveModelling'

const MainPredictiveContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    // background:pink;
    margin-left: 20%;
    padding: 20px;
    font-family: 'Outfit', sans-serif;
    `

    const Heading = styled.h1`
    font-size: 2em;
    font-weight: 400;
    margin-bottom: 20px;
    color: #3c4c4b;
    margin-left: 20px;
    `


const MainPredictive = () => {
  return (
    <MainPredictiveContainer>
      <Heading>Predictive Analytics</Heading>
      <EnergyForecast />
      <DemandForecast />
      <MarketTrends />
      <RevenueOpportunities />
      <AIRecommendations />
      <InteractiveModelling />
    </MainPredictiveContainer>
  )
}

export default MainPredictive

import React from 'react';
import styled from 'styled-components';

// Generic box for content
const Box = styled.div`
  border: 2.2px solid #3c4c4b;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
`;

// Styled component for the Tips Container
const TipsBox = styled(Box)`
  flex: 1;
  height: 90%; // Taking up the full height of the dashboard
  overflow: auto; // In case of many tips
`;

// Styled component for individual Tip
const Tip = styled.div`
//   background-color: white;
border: 2.2px solid #3c4c4b;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  border-left: 5px solid #2196F3; // Consistent color for informational tips
`;

// Energy-saving tips for households
const energySavingTips = [
  { id: 1, text: "Switch off lights and appliances when not in use." },
  { id: 2, text: "Use energy-efficient LED bulbs instead of traditional incandescent bulbs." },
  { id: 3, text: "Install a programmable thermostat to better control heating and cooling." },
  { id: 4, text: "Seal windows and doors to prevent air leaks and improve insulation." },
  { id: 5, text: "Use appliances during off-peak hours to save on energy costs." },
  { id: 6, text: "Regularly clean or replace HVAC filters to improve efficiency." },
  // Add more tips as needed
];

const Tips = () => {
  return (
    <TipsBox>
      {energySavingTips.map(tip => (
        <Tip key={tip.id}>
          {tip.text}
        </Tip>
      ))}
    </TipsBox>
  );
};

export default Tips;

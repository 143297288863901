import React from 'react'
import SideNav from '../components/SideNav'

const Settings = () => {
  return (
    <div>
      <SideNav />
    </div>
  )
}

export default Settings

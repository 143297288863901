import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { db } from '../../Firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import styled, { keyframes } from 'styled-components';

const waveAnimation = keyframes`
  0%, 100% { border-radius: 10px 15px 10px 15px; }
  25% { border-radius: 15px 10px 15px 10px; }
  50% { border-radius: 10px 5px 10px 5px; }
  75% { border-radius: 5px 10px 5px 10px; }
`;

const ChartContainer = styled.div`
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  position: relative;
  &:hover {
    animation: ${waveAnimation} 1.5s infinite linear;
  }
`;

const Title = styled.h3`
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #333;
`;

const StyledSvg = styled.svg`
  width: 100%;
  height: 400px;
  overflow: visible;
`;

const Tooltip = styled.div`
  position: absolute;
  text-align: center;
  width: 120px;
  height: 28px;
  padding: 2px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
`;

const AxisLabel = styled.div`
  position: absolute;
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
`;

const XAxisLabels = styled(AxisLabel)`
  bottom: 0;
  left: 24%;
  padding: 12px;
  width: 100%;
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  text-align: center;
`;

const YAxisLabels = styled(AxisLabel)`
  top: 36%;
  left: 0;
  margin-left: 60px;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  writing-mode: vertical-lr;
  transform: translateX(-100%);
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
`;

const EnergyProductionChart = () => {
  const chartRef = useRef(null);
  const [tooltipData, setTooltipData] = useState({ visible: false, data: null, position: { x: 0, y: 0 } });

  const drawChart = async () => {
    const q = query(collection(db, "farmData"), where("farm_id", "==", "FARM000"));
    const querySnapshot = await getDocs(q);
    let totalEnergyProduced = 0;
    let dataWithTotal = [];
    let zeroEnergyCounter = 0;

    querySnapshot.docs.forEach((doc, index) => {
      if (index % 90 === 0 && index !== 0) {
        for (let i = 0; i < 75; i++) {
          dataWithTotal.push({
            energy_produced_kWh: totalEnergyProduced,
            timestamp: new Date(2024, 0, 1, 8, 0).getTime() + (index + zeroEnergyCounter + i) * 15 * 60000
          });
        }
        zeroEnergyCounter += 75;
      }
      const energyProduced = doc.data().energy_produced_kWh;
      totalEnergyProduced += energyProduced;
      dataWithTotal.push({
        energy_produced_kWh: totalEnergyProduced,
        timestamp: new Date(2024, 0, 1, 8, 0).getTime() + (index + zeroEnergyCounter) * 15 * 60000
      });
    });

    if (chartRef.current) {
      const margin = { top: 30, right: 85, bottom: 60, left: 60 };
      const svg = d3.select(chartRef.current).append('g').attr('transform', `translate(${margin.left},${margin.top})`);
      const width = svg.node().parentNode.getBoundingClientRect().width - margin.left - margin.right;
      const height = 400 - margin.top - margin.bottom;
      const xScale = d3.scaleTime().domain(d3.extent(dataWithTotal, d => new Date(d.timestamp))).range([0, width]);
      const yScale = d3.scaleLinear().domain([0, d3.max(dataWithTotal, d => d.energy_produced_kWh)]).range([height, 0]);
      const lineGenerator = d3.line()
                              .x(d => xScale(new Date(d.timestamp)))
                              .y(d => yScale(d.energy_produced_kWh));

      svg.append("path")
         .datum(dataWithTotal)
         .attr("fill", "none")
         .attr("stroke", "#ff5722")
         .attr("stroke-width", 2)
         .attr("d", lineGenerator);
    }
  };

  useEffect(() => {
    drawChart();
  }, []);

  return (
    <ChartContainer>
      {tooltipData.visible && (
        <Tooltip style={{ left: tooltipData.position.x, top: tooltipData.position.y }}>
          {tooltipData.data}
        </Tooltip>
      )}
      <Title>Total Energy Produced (kWh)</Title>
      <StyledSvg ref={chartRef} />
      <XAxisLabels>
        <p>Time (15-minute intervals)</p>
      </XAxisLabels>
      <YAxisLabels>
        <p>Total Energy Produced (kWh)</p>
      </YAxisLabels>
    </ChartContainer>
  );
};

export default EnergyProductionChart;

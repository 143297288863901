import React from 'react';
import styled from 'styled-components';

// add time and more info to notifications
// add a modal to show more info on click

// Generic box for content
const Box = styled.div`
  border: 2px solid #3c4c4b;
  // background: #ededed;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
`;

const Wrapper = styled.div`
  // display: flex;
  // padding: 20px;
  // flex-direction: column;
  // justify-content: center;
  `;

// Styled component for the Notifications
const NotificationsBox = styled(Box)`
  flex: 1;
  // height: 90%; // Taking up the full height of the dashboard
  height: 70vh; // Taking up the full height of the dashboard
  overflow:scroll; // In case of many notifications
  // overflow: auto; // In case of many notifications
`;

// Styled component for individual Notification
const Notification = styled.div`
  // background-color: white;
  border: 2.2px solid #3c4c4b;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  border-left: 5px solid; // Add a colored border for visual categorization
`;

// Example notifications mimicking a solar farm setup
const dummyNotifications = [
  { id: 1, text: "Energy production reached 95% of daily target.", type: "success" },
  { id: 2, text: "Maintenance required for Inverter 3.", type: "warning" },
  { id: 3, text: "New software update available.", type: "info" },
  { id: 4, text: "System error detected in Solar Panel Array B.", type: "error" },
  { id: 5, text: "Unexpected shutdown detected in Solar Panel Array D.", type: "error" },
  { id: 6, text: "Battery storage at 80% capacity.", type: "info" },
  { id: 7, text: "Grid connection restored after outage.", type: "success" },
  { id: 8, text: "Increased energy demand detected. Adjusting production.", type: "info" },
  { id: 9, text: "Weather alert: Storm approaching. Secure loose equipment.", type: "warning" },
  { id: 10, text: "Performance anomaly detected in Tracker System 5.", type: "error" },
  { id: 11, text: "Scheduled maintenance due next week for Array A.", type: "info" },
  { id: 12, text: "Firmware update completed successfully.", type: "success" },
  { id: 13, text: "Remote access attempt detected. Verify if authorized.", type: "warning" },
  { id: 14, text: "Energy production today surpassed expected targets!", type: "success" },
  { id: 15, text: "System error detected in Inverter 6.", type: "error" },
  { id: 16, text: "Battery storage at 90% capacity.", type: "info" },
  { id: 17, text: "Grid connection lost. Investigating the cause.", type: "error" },
  { id: 18, text: "Increased energy demand detected. Adjusting production.", type: "info" },
  { id: 19, text: "Weather alert: Storm approaching. Secure loose equipment.", type: "warning" },
  { id: 20, text: "Performance anomaly detected in Tracker System 5.", type: "error" },
];

const Notifications = () => {
  return (
    <NotificationsBox>
        <Wrapper>
      {dummyNotifications.map(notification => (
        <Notification key={notification.id} style={{ borderColor: notificationColor(notification.type) }}>
          {notification.text}
        </Notification>
      ))}
      </Wrapper>
    </NotificationsBox>
  );
};

// Function to determine border color based on notification type
const notificationColor = (type) => {
  switch (type) {
    case 'success':
      return '#4CAF50'; // Green
    case 'warning':
      return '#FFC107'; // Amber
    case 'info':
      return '#9E9E9E'; // Blue
    case 'error':
      return '#F44336'; // Red
    default:
      return '#9E9E9E'; // Grey for undefined types
  }
};

export default Notifications;

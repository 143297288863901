import React, { useState } from 'react';
import styled from 'styled-components';

// Styled components for FarmRevenue
const RevenueContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OfferCard = styled.div`
//   background: #f3d4bd;
background: #ededed;
margin-left: 20px;
//   border: 1px solid #ccc;
  padding: 25px;
  border-radius: 8px;
  max-width: fit-content;
  margin-bottom: 10px;
`;

const CardHeader = styled.h3`
  font-size: 1.2em;
  color: #333;margin-top: 0;
  font-weight: 400;
  margin-left: 20px;
  text-transform: uppercase;
`;

const CardBody = styled.div`
  font-size: 1.1em;
  padding: 3px;
  color: #3c4c4b;
  margin-bottom: 5px;
`;

const CardBody2 = styled.div`
  font-size: 3em;
  font-weight: 300;
  padding-left: 25px;
  color: #3c4c4b;
  margin-bottom: 45px;
`;

const Button = styled.button`
    background-color: #f3d4bd;
  color: black;
  font-family: 'Outfit', sans-serif;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
    transition: background-color 0.3s ease;
    &:hover {
        background-color:#0a7a3b;
        color: white;
    }
`;

const Offers = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
`;

const Offers2 = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 25px;
`;

// Dummy data for current energy offers
const energyOffers = [
  {
    id: 1,
    price: "0.10",
    amount: "1000",
    validity: "30 Days",
  },
  {
    id: 2,
    price: "0.09",
    amount: "2000",
    validity: "60 Days",
  },
];

const FarmRevenue = () => {
  // State for total revenue and payouts
  const [totalRevenue, setTotalRevenue] = useState(195803.15); // Dummy total revenue
  const [payoutPerShare, setPayoutPerShare] = useState(totalRevenue/18623000); // Dummy payout per share

  return (
    <RevenueContainer>
      <CardHeader>Current Energy Offers</CardHeader>
      <Offers>
      {energyOffers.map((offer) => (
        <OfferCard key={offer.id}>
          <CardBody>Price per kWh: ${offer.price}</CardBody>
          <CardBody>Amount: {offer.amount} kWh</CardBody>
          <CardBody>Validity Period: {offer.validity}</CardBody>
          <Button>Accept</Button>
        </OfferCard>
      ))}
      </Offers>
      <Offers2>
        <div>
      <CardHeader>Total Revenue Generated</CardHeader>
      <CardBody2>
        ${totalRevenue.toFixed(2)}
        </CardBody2></div><div>
      <CardHeader>Total Payout Per Share</CardHeader>
      <CardBody2>
        ${payoutPerShare.toFixed(4)}
        </CardBody2></div></Offers2>
    </RevenueContainer>
  );
};

export default FarmRevenue;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import sunny from '../../assets/sunny.png';
import partlyCloudy from '../../assets/cloudy.png';

const Div = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px;
`;

const H2 = styled.h2`
    margin-bottom: 10px;
    font-size: 1.5em;
    margin-left: 10px;
    font-weight: 400;
`;

const Ul = styled.ul`
    list-style-type: none;
    padding: 0;
    display: grid;
    margin-right: 35px;
    grid-template-columns: repeat(2, 1fr);
`;

const Li = styled.li`
    margin-bottom: 25px;
    padding: 10px;
    border-radius: 10px;

    &:hover {
        // background-color: #f0f0f0;
    }
`;

const Day = styled.div`
font-weight: 300;
font-size: 1.8em;
margin-bottom: 10px;
`;

const EnergyProduction = styled.div`
font-weight: 400;  
font-size: 1.2em;
margin-bottom: 10px;
`;

const Weather = styled.div`
font-weight: 400;
padding-right: 10px
`;

const Flexer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
`;

const SeasonalTrend = styled.div`
font-weight: 400;
`;

const WeatherIcon = styled.img`
    width: 30px;
    height: auto;
    margin-right: 30px;
    `;



const ForecastOverview = () => {
    // Dummy data for the next 7 days (including energy production, weather, and seasonal trend predictions)
    const [forecastData, setForecastData] = useState([
        {
            day: 'Monday',
            energyProduction: 350,
            weather: 'Sunny',
            seasonalTrend: 'Stable'
        },
        {
            day: 'Tuesday',
            energyProduction: 380,
            weather: 'Partly Cloudy',
            seasonalTrend: 'Rising'
        },
        {
            day: 'Wednesday',
            energyProduction: 360,
            weather: 'Sunny',
            seasonalTrend: 'Stable'
        },

        {
            day: 'Thursday',
            energyProduction: 370,
            weather: 'Sunny',
            seasonalTrend: 'Stable'
        },

        {
            day: 'Friday',
            energyProduction: 390,
            weather: 'Sunny',
            seasonalTrend: 'Rising'
        },

        {
            day: 'Saturday',
            energyProduction: 400,
            weather: 'Sunny',
            seasonalTrend: 'Rising'
        },
        {
            day: 'Sunday',
            energyProduction: 410,
            weather: 'Sunny',
            seasonalTrend: 'Rising'
        }

        // Include predictions for the remaining days here
    ]);

    // Use useEffect to fetch and update the weather and seasonal trend predictions
    useEffect(() => {
        // Add code to fetch and update weather and seasonal trend predictions here
    }, []); // Empty dependency array to run the effect only once

    return (
        <Div>
            <H2>Forecast Overview</H2>
            <Ul>
                {forecastData.map((day) => (
                    <Li key={day.day}>
                        {/* {day.day}:

                        Energy Production - {day.energyProduction} kWh,
                        Weather - {day.weather},
                        Seasonal Trend - {day.seasonalTrend} */}
                        <Day>{day.day}</Day>
                        <EnergyProduction>Energy Production - {day.energyProduction} kWh</EnergyProduction>
                        <Flexer>
                            <Weather>Weather</Weather>
                            <WeatherIcon src={day.weather === 'Sunny' ? sunny : partlyCloudy} alt={day.weather} />
                        <SeasonalTrend>Seasonal Trend - {day.seasonalTrend}</SeasonalTrend></Flexer>
                    </Li>
                ))}
            </Ul>
        </Div>
    );
};

export default ForecastOverview;
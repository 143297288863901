import React from 'react'
import SideNav from '../components/SideNav'
import MainHistory from '../components/MainHistory'
import styled from 'styled-components'

const HistoryContainer = styled.div`

    display: flex;  
    flex-direction: row;
    `

const History = () => {
  return (
    <HistoryContainer>
      <SideNav />
      <MainHistory />
    </HistoryContainer>
  )
}

export default History

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import GridSimulation from './GridSimulation';
import Legend from './Legend';
import SunSlider from './SunSlider';
import ReinvestmentPercentage from './ReinvestmentPercentage';
// import CTA2 from '../sections/CTA2';
// import Banner from '../elements/Banner';

const Container = styled.div`
    margin-top: 60px;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // background: #f3d4bd;0px;
`;

const Results = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // background should be slightly darker than background of page
    // background: #f3d4bd; this was the original color make it darker
    background:#3c4c4b;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
    // border: 2px solid #3c4c4b;
    margin-left: 50px;
`;

const P = styled.p`
    font-size: 1rem;
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit', 'Noto Sans', sans-serif;
    margin: 0;
    color: #fff;
    padding: 20px;
    width: 100%;

    &:hover {
        // darker shade of the color]
        background-color: #2a3635;
    }
`;

const Heading = styled.h1`
    font-size: 1.4rem;
    font-weight: 400;
    color: #3c4c4b;
    font-family: 'Outfit', 'Noto Sans', sans-serif;
    margin: 0;
    padding: 20px;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
`;

const Flexer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Flexer2 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 90%;
`;

function calculateAnnualSolarEnergyProduction(capacityInMW, capacityFactor, averageSunHoursPerDay) {
    const hoursPerYear = 8760;
    return capacityInMW * capacityFactor * averageSunHoursPerDay * hoursPerYear;
}

function capacityGrowth(initialCapacityMW, targetEnergyKWh, reinvestmentRate, capacityFactor, averageSunHoursPerDay, initialEnergyPricePerKWh) {
    let totalEnergyProducedKWh = 0;
    let currentCapacityMW = initialCapacityMW;
    let totalProfit = 0;
    let totalExpansionCost = 0;
    let years = 0;
    const deflationRate = 0.1;
    const hoursPerYear = 8760;
    const maxYears = 100;
    const costPerMW = 11 / 8; // $1.375 million per MW
    let currentEnergyPricePerKWh = initialEnergyPricePerKWh;

    while (totalEnergyProducedKWh < targetEnergyKWh && years < maxYears) {
        const annualEnergyKWh = calculateAnnualSolarEnergyProduction(currentCapacityMW, capacityFactor, averageSunHoursPerDay);
        totalEnergyProducedKWh += annualEnergyKWh;
        const annualProfit = annualEnergyKWh * currentEnergyPricePerKWh;
        totalProfit += annualProfit;

        const reinvestedProfit = annualProfit * reinvestmentRate;
        const reinvestedEnergyKWh = reinvestedProfit / currentEnergyPricePerKWh;
        const additionalCapacityMW = reinvestedEnergyKWh / (capacityFactor * averageSunHoursPerDay * hoursPerYear);
        const expansionCost = additionalCapacityMW * costPerMW; // in millions

        if (reinvestedProfit >= expansionCost) {
            currentCapacityMW += additionalCapacityMW;
            totalExpansionCost += expansionCost;
        } else {
            // Handle insufficient profit for expansion, e.g., by limiting capacity increase
        }

        currentEnergyPricePerKWh *= (1 - deflationRate);
        years++;
    }

    const plantCostBasedOnOneYearProfit = totalProfit / Math.max(years, 1);
    const plantCostBasedOnTwoYearsProfit = 2 * plantCostBasedOnOneYearProfit;

    return {
        yearsToTarget: years,
        finalCapacityMW: currentCapacityMW,
        totalProfitEarned: totalProfit,
        plantCostBasedOnOneYearProfit,
        plantCostBasedOnTwoYearsProfit,
        totalExpansionCost
    };
}

const InteractiveModelling = () => {
    const [sunlightHours, setSunlightHours] = useState(6);
    const [reinvestmentRate, setReinvestmentRate] = useState(0.8);
    const [currentCapacity, setCurrentCapacity] = useState(8);
    const [simulationResults, setSimulationResults] = useState({});
    const previousCapacity = useRef(currentCapacity);

    useEffect(() => {
        const calculateResults = () => capacityGrowth(
            currentCapacity,
            1e9,
            reinvestmentRate,
            0.2,
            sunlightHours,
            0.1
        );

        if (currentCapacity !== previousCapacity.current) {
            const results = calculateResults();
            setSimulationResults(results);
            previousCapacity.current = currentCapacity;
        }
    }, [sunlightHours, reinvestmentRate, currentCapacity]);

    const handleCapacityChange = (newCapacity) => {
        setCurrentCapacity(newCapacity);
    };

    return (
        <Container>
            {/* <Banner /> */}
            <Flexer2>
            <Flexer>
                <SunSlider onSunlightChange={setSunlightHours} />
            </Flexer>
            <Flexer>
                <Heading>Number of Solar Plants On Chain</Heading>
                <GridSimulation onCapacityChange={handleCapacityChange} />
                <Legend />
            </Flexer>
            <div>
            <Flexer>
                <ReinvestmentPercentage
                    reinvestment={reinvestmentRate}
                    setReinvestment={setReinvestmentRate}
                />
            </Flexer>
            <Flexer>
                <Results>
                    <P>Years to Target: {simulationResults.yearsToTarget}</P>
                    <P>Final Capacity: {simulationResults.finalCapacityMW} MW</P>
                    <P>Total Profit: ${simulationResults.totalProfitEarned}</P>
                    <P>Plant Cost for One Year Profit: ${simulationResults.plantCostBasedOnOneYearProfit}</P>
                    <P>Plant Cost for Two Years Profit: ${simulationResults.plantCostBasedOnTwoYearsProfit}</P>
                </Results>
            </Flexer></div>
            </Flexer2>
            {/* <CTA2 /> */}
        </Container>
    );
};

export default InteractiveModelling;

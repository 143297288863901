import React from 'react'
import styled, { keyframes } from 'styled-components';


const CurrentBalanceContainer = styled.div`

    `
    const waveAnimation = keyframes`
    0%, 100% {
        border-radius: 2    5% 10% 10% 20%;
      }
    //   10% {
    //     border-radius: 40% 50% 40% 30%;
    //   }
    //   20% {
    //     border-radius: 30% 40% 50% 40%;
    //   }
      30% {
        border-radius: 20% 10% 15% 20%;
      }
    //   40% {
    //     border-radius: 50% 40% 30% 40%;
    //   }
      50% {
        border-radius: 15% 22% 10% 10%;
      }
    //   60% {
    //     border-radius: 30% 40% 50% 40%;
    //   }
      70% {
        border-radius: 15% 5% 15% 20%;
      }
    //   80% {
    //     border-radius: 50% 40% 30% 40%;
    //   }
    //   90% {
    //     border-radius: 40% 50% 40% 30%;
    //   }
  `;
  
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    max-width: fit-content;
    // background-color: #f3d4bd;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    margin-left: 20px;
    transition: background-color 0.3s ease;
    &:hover {
    //   background-color: #ebc4a7; // Optional: change background color on hover
      animation: ${waveAnimation} 1.5s infinite linear;
    }
  `;
const H3 = styled.p`
    font-size: 1em;   
    font-weight: 400;
    margin: 0;
    color: #3c4c4b;
    text-transform: uppercase;
    `
const P = styled.p`
    font-size: 2.2em;
    font-weight: 300;
    margin: 0;
    padding: 0;
    `


const CurrentBalance = () => {
    return (
        <CurrentBalanceContainer>
            <Box>
            <H3>Current Balance</H3>
            <P>10322.52 USDC</P>
            </Box>
        </CurrentBalanceContainer>
    )
}

export default CurrentBalance

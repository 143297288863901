import React from 'react'
import SideNav from '../components/SideNav'
import MainDAO from '../components/MainDAO'

const DAO = () => {
  return (
    <div>
      <SideNav />
      <MainDAO />
    </div>
  )
}

export default DAO

import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import panellight from '../../assets/panel-light.png';
import panelDark from '../../assets/panel-dark.png';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 0px 0px;
  margin-right: 30px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
`;

const Panel = styled.div`
  width: 50px;
  height: auto;
  aspect-ratio: 1;
  background-image: url(${props => props.isLight ? panellight : panelDark});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 5px;
  cursor: pointer;
`;

const GridSimulation = ({ onCapacityChange }) => {
  const totalPanels = 25;
  const [activePanels, setActivePanels] = useState(Array(totalPanels).fill(false));

  const handleClick = useCallback((index) => {
    setActivePanels(prevPanels => {
      const newActivePanels = [...prevPanels];
      newActivePanels[index] = !newActivePanels[index];
      
      const newCapacity = newActivePanels.filter(isActive => isActive).length;
      console.log("Calculated Capacity in Grid:", newCapacity); // Debugging line
      if (onCapacityChange) {
        onCapacityChange(newCapacity);
      }
      return newActivePanels;
    });
  }, [onCapacityChange]);
  

  return (
    <GridContainer>
      {activePanels.map((isLight, index) => (
        <Panel key={index} isLight={isLight} onClick={() => handleClick(index)} />
      ))}
    </GridContainer>
  );
};

export default GridSimulation;

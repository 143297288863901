import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// const [shares, setShares] = useState([
//     {
//         price: 1.2,
//         totalShares: 18623000,
//         soldShares: 7900000,
//         availableShares: 10723000

//     }
// ]);

// If that is the shares data for this owner show on a progress bar how much percent control they have as the owner and how much the DAO has
// That is the purpose of this component
const ProgressBar = styled.div`
margin-top:25px;
    width: 100%;
    background-color: #ddd;
    border-radius: 12px; /* Adds rounded corners to the ProgressBar */
`;

const Progress = styled.div`
    height: 24px;
    background-color: #4CAF50;
    width: 60%;
    border-radius: 10px; /* Adds rounded corners to the Progress */
    /* For a perfectly rounded progress bar at all progress states, you might want to only apply border-radius to the left side and adjust based on progress */
`;

const RightLabel = styled.div`
    float: right;
`;

const LeftLabel = styled.div`
    float: left;
`;

const Div = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
`;

const Head = styled.h1`
    font-size: 1.3em;
    font-weight: 400;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #3c4c4b;
`;

const AnotherDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
`;

const Container = styled.div`
padding:20px;
padding-right: 80px;
`



const ControlTracker = () => {
    const [shares, setShares] = useState([
        {
            price: 1.2,
            totalShares: 18623000,
            soldShares: 7900000,
            availableShares: 10723000

        }
    ]);

    const [control, setControl] = useState(0);

    useEffect(() => {
        setControl((shares[0].availableShares / shares[0].totalShares) * 100);
    }, [shares]);

    return (
        <Container>
            <Head>Control Tracker</Head>
            <ProgressBar>
                <Progress style={{ width: `${control}%` }}></Progress>
            </ProgressBar>
            <AnotherDiv>
                <LeftLabel>You  {
                    control.toFixed(2)
                }%
                </LeftLabel>
                <RightLabel>DAO  {(100 - control).toFixed(2)
                }%
                </RightLabel>
            </AnotherDiv>

        </Container>
    )
}

export default ControlTracker

import React from 'react'
import SideNav from '../components/SideNav'
import MainPredictive from '../components/MainPredictive'

const PredictiveAnalytics = () => {
  return (
    <div>
      <SideNav />
      <MainPredictive />
    </div>
  )
}

export default PredictiveAnalytics

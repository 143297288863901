import React from 'react'
import styled from 'styled-components'

const ConnectedButtonContainer = styled.div`
    position: fixed;
    bottom: 0;
    align-items: center;
    justify-content: center;
    // background:pink;
    display: flex;
    flex-direction: column;
    `

const CircleButton = styled.button`
    background-color: #f3d4bd;
    color: black;
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 50%;
    font-size: 1.2em;

    `

    const Text = styled.p`
    font-size: 0.65em;
    font-weight: 400;
    font-family: 'Outfit', sans-serif;
    text-transform: uppercase;
    color: white;
    margin-bottom: 30px;
    `


const ConnectedButton = () => {
  return (
    <ConnectedButtonContainer>
      <CircleButton>
        
      </CircleButton>
        <Text>Connected</Text>
    </ConnectedButtonContainer>
  )
}

export default ConnectedButton

import React from 'react'
import styled from 'styled-components'
import CurrentBalance from './CurrentBalance'
import NotificationButton from './NotificationButton'
import FarmProfileSummary from './FarmProfileSummary'
import TotalEnergyProduced from './TotalEnergyProduced'
import AvailableSharesComponent from './AvailableSharesComponent'
import FarmRevenue from './FarmRevenue'
import ControlTracker from './ControlTracker'

const BackgroundContainer = styled.div`

min-height: 100vh;
display: flex;
flex-direction: column;
padding: 20px;
margin-left: 20%;
background: #f9f5f5;
font-family: 'Outfit', sans-serif;
`

const Flexer = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
// justify-content: center;
`


const MainMarket = () => {
  return (
    <BackgroundContainer>
        <Flexer>
        <FarmProfileSummary />

        <TotalEnergyProduced />

        <CurrentBalance />
        </Flexer>
        <NotificationButton />
        <ControlTracker />
        <AvailableSharesComponent />
        <FarmRevenue />
    </BackgroundContainer>
  )
}

export default MainMarket

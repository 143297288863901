import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { db } from '../../Firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import styled, { keyframes } from 'styled-components';

// Styled components for visual design
const waveAnimation = keyframes`
0%, 100% {
  border-radius: 10px 15px 10px 15px;
}
25% {
  border-radius: 15px 10px 15px 10px;
}
50% {
  border-radius: 10px 5px 10px 5px;
}
75% {
  border-radius: 5px 10px 5px 10px;
}
`;

const Box = styled.div`
display: flex;
flex-direction: column;
max-width: fit-content;
// background-color: #f3d4bd;
align-items: center;
justify-content: center;
border-radius: 10px;
padding: 20px;
// margin-left: 20px;
transition: background-color 0.3s ease;
&:hover {
//   background-color: #ebc4a7; // Optional: change background color on hover
  animation: ${waveAnimation} 1.5s infinite linear;
}
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    margin-left: 20px;
    max-width: fit-content;
    `;


    // const Box = styled.div`
    // background: #f3d4bd;
    // border-radius: 10px;
    // padding: 20px;
    // `;

    const H3 = styled.h3`
    font-size: 1em;   
    font-weight: 400;
    margin: 0;
    color: #3c4c4b;
    text-transform: uppercase;

    `;
    const P = styled.p`
    font-size: 2.2em;
    font-weight: 300;
    margin: 0;
    padding: 0;
    text-align: center;
    `;
    const Flexer = styled.div`
    display: flex;

    `;

const TotalEnergyProduced = () => {

    const [revenue, setRevenue] = useState(0);
    const [energy, setEnergy] = useState(0);

    // const q = query(collection(db, "farmData"), where("farm_id", "==", "FARM000"));
    // const querySnapshot = await getDocs(q);
    // const totalEnergyProduced = querySnapshot.docs.reduce((acc, doc) => acc + doc.data().energy_produced_kWh, 0);
    // const totalRevenueGenerated = totalEnergyProduced * 0.13; // Price per kWh
    // setRevenue(totalRevenueGenerated);
    // setEnergy(totalEnergyProduced);

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, "farmData"), where("farm_id", "==", "FARM000"));
            const querySnapshot = await getDocs(q);
            const totalEnergyProduced = querySnapshot.docs.reduce((acc, doc) => acc + doc.data().energy_produced_kWh, 0);
            const totalRevenueGenerated = totalEnergyProduced * 0.13; // Price per kWh
            setRevenue(totalRevenueGenerated);
            setEnergy(totalEnergyProduced);
        };

        fetchData();
    }
    , []);

    return (
        <Container>
            <Box>
                <H3>Total Energy Produced</H3>
                <P>{energy.toFixed(2)} kWh</P>
            </Box>
            {/* <Box>
                <H3>Total Revenue Generated</H3>
                <P>${revenue.toFixed(2)}</P>
            </Box> */}
        </Container>
    );
        

}

    export default TotalEnergyProduced;

    
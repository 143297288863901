import React from 'react'
import styled from 'styled-components'
import ForecastOverview from './ForecastOverview'
import DailyProductionChart from './DailyProductionChart'
import EnergyPredictionFactors from './EnergyPredictionFactors'

const EnergyForecastContainer = styled.div`
    display: flex;
    flex-direction: row;
    `

const Div = styled.div`
    display: flex;
    flex-direction: column;
    // margin-left: 150px;
    `


const EnergyForecast = () => {
  return (
    <EnergyForecastContainer>
        <ForecastOverview />
        <Div>
        <DailyProductionChart />
        <EnergyPredictionFactors /></Div>
    </EnergyForecastContainer>
  )
}

export default EnergyForecast

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import logo from '../../assets/logolight.png'

const SideNavWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    // width: 100px;
    width: 20%;
    position: fixed;
    top: 0;
    left: 0;
    background: #3c4c4b;
    transition: 850ms;
    z-index: 1000;
`

const StyledMenuItem = styled(Link)`
    color: white;
    text-decoration: none;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    padding: 20px;
    font-family: 'Outfit','Noto Sans', sans-serif;
    &:hover {
        color: #f3d4bd;
    }
`
// fix position at the top of the navbar
const Logo = styled.img`
    width: 50px;
    height: auto;
    padding: 20px;
    position: fixed;
    top: 25px;
    // left: 0;
    z-index: 1000;
    transition: 850ms;
`


const SideNav = () => {
  return (
    <SideNavWrapper>
      <Logo src
        ={logo} alt="logo" />   
        <StyledMenuItem to="/household">Dashboard</StyledMenuItem>
        <StyledMenuItem to="/history">History</StyledMenuItem>
        <StyledMenuItem to="/predictive-analytics">Savings Goals</StyledMenuItem>
        <StyledMenuItem to="/settings">Settings</StyledMenuItem>
    </SideNavWrapper>

  )
}

export default SideNav

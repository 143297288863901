import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { db } from '../../Firebase';
import { collection, query, getDocs } from 'firebase/firestore';

// Styled components
const Box = styled.div`
  border: 2.2px solid #3c4c4b;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
`;

const SpendingBox = styled(Box)`
  flex: 2;
  height: calc(50% - 20px); // Adjusted as per requirement
`;

const SpendingHeading = styled.h3`
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  margin-bottom: 15px;
  color: #3c4c4b;
`;

const SpendingValue = styled.p`
  font-size: 1.6em;
  font-weight: 600;
  margin: 0;
  color: #3c4c4b;
`;

const SpendingTracker = () => {
  const [spending, setSpending] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "homeEnergyData"));
      const querySnapshot = await getDocs(q);
      const totalEnergyConsumed = querySnapshot.docs.reduce((acc, doc) => acc + doc.data().energy_consumed_kWh, 0);
      const totalSpending = totalEnergyConsumed * 0.15; // Price per kWh
      setSpending(totalSpending);
    };

    fetchData();
  }, []);

  return (
    <SpendingBox>
      <SpendingHeading>Total Energy Spending</SpendingHeading>
      <SpendingValue>${spending.toFixed(2)}</SpendingValue>
    </SpendingBox>
  );
};

export default SpendingTracker;

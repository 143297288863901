import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import { db } from '../../Firebase';
import { collection, query, getDocs } from 'firebase/firestore';

// Styled components as before
const Box = styled.div`
  border: 2.2px solid #3c4c4b;
  border-radius: 10px;
  padding: 30px;
  padding-bottom: 50px;
  padding-left: 50px;
  margin: 10px;
  position: relative;   
  
`;

const AxisLabel = styled.div`
  position: absolute;
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
`;

const XAxisLabels = styled(AxisLabel)`
  bottom: 0;
    left: 50%;
margin-bottom: 20px;
  padding: 0px; /* Adjust based on bottom margin and axis label heights */
  width: 100%;
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  text-align: center;
`;

const YAxisLabels = styled(AxisLabel)`
  left: 0;
  top: 20%; /* Adjust based on left margin */
  margin-left: 50px; /* Adjust based on left margin */
  bottom: 40px; /* Adjust based on bottom margin and axis label heights */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  writing-mode: vertical-rl; /* Makes text go vertical, use 'vertical-rl' for reverse direction */
  transform: translateX(-100%); /* Adjust as necessary to align properly */
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;

`;

const EnergyConsumptionChartBox = styled(Box)`
  flex: 2;
  height: 400px; // Adjusted for visual clarity
`;

const Heading3 = styled.h3`
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  margin-bottom: 15px;
  color: #3c4c4b;
`;

const EnergyConsumptionChart = () => {
  const chartRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "homeEnergyData"));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc, index) => ({
        ...doc.data(),
        // Simplification: use index as a proxy for different days
        day: index + 1
      }));

      drawChart(data);
    };

    const drawChart = (data) => {
      const svg = d3.select(chartRef.current);
      const margin = { top: 20, right: 10, bottom: 20, left: 30 };
      const width = svg.node().clientWidth - margin.left - margin.right;
      const height = svg.node().clientHeight - margin.top - margin.bottom;

      // Scales
      const x = d3.scaleBand()
        .range([margin.left, width - margin.right])
        .padding(0.1)
        .domain(data.map(d => d.day));

      const y = d3.scaleLinear()
        .range([height - margin.bottom, margin.top])
        .domain([0, d3.max(data, d => d.energy_consumed_kWh)]);

      // Axes
      svg.append("g")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).tickFormat(d => `${d}`))
        // .style("font-size", "0.8em")
        .style("font-family", "Outfit, sans-serif")

      svg.append("g")
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        // .style("font-size", "0.8em")
        .style("font-family", "Outfit, sans-serif")

      // Bars
      svg.selectAll(".bar")
        .data(data)
        .enter().append("rect")
          .attr("class", "bar")
          .attr("x", d => x(d.day))
          .attr("y", d => y(d.energy_consumed_kWh))
          .attr("width", x.bandwidth())
          .attr("height", d => height - margin.bottom - y(d.energy_consumed_kWh))
          .attr("fill", "#eb8334");
    };

    fetchData();
  }, []);

  return (
    <EnergyConsumptionChartBox>
      <Heading3>Household Energy Consumption (kWh)</Heading3>
      <svg ref={chartRef} width="100%" height="100%"></svg>
        <XAxisLabels>
            Days
        </XAxisLabels>
        <YAxisLabels>
            Energy consumed (kWh)
        </YAxisLabels>
    </EnergyConsumptionChartBox>
  );
};

export default EnergyConsumptionChart;

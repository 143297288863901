import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import { db } from '../../Firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

// Styled components
const Box = styled.div`
  // border: 2.2px solid #3c4c4b;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
`;

const SunlightIntensityChartBox = styled(Box)`
  flex: 2;
  height: calc(85% - 20px); // Adjusted for visual clarity
`;

const Heading3 = styled.h3`
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  margin-bottom: 15px;
  color: #3c4c4b;
`;

const SunlightIntensityChart = () => {
  const chartRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "farmData"), where("farm_id", "==", "FARM000"));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc, index) => ({
        ...doc.data(),
        // Assuming a 15-minute interval for each reading and converting to a readable date format
        timestamp: new Date(2020, 0, 1, 0, 0).getTime() + index * 15 * 60000
      }));

       // Keep only the last 10 values
      //  data = data.slice(-10);

      drawChart(data.slice(-70));
    };

    const drawChart = (data) => {
      const svg = d3.select(chartRef.current);
      const margin = { top: 5, right: 5, bottom: 10, left: 10 };
      const width = svg.node().clientWidth - margin.left - margin.right;
      const height = svg.node().clientHeight - margin.top - margin.bottom;

      // Scale for X
      const x = d3.scaleTime()
        .domain(d3.extent(data, d => new Date(d.timestamp)))
        .range([margin.left, width - margin.right]);

      // Scale for Y
      const y = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.sunlight_intensity_watts_per_sq_meter)])
        .range([height - margin.bottom, margin.top]);

      // Line generator for sunlight intensity
      const line = d3.line()
        .x(d => x(new Date(d.timestamp)))
        .y(d => y(d.sunlight_intensity_watts_per_sq_meter));

      // svg.append("g")
      //   .attr("transform", `translate(0,${height - margin.bottom})`)
      //   .call(d3.axisBottom(x));

      // svg.append("g")
      //   .attr("transform", `translate(${margin.left},0)`)
      //   .call(d3.axisLeft(y));

      // Draw the line
      svg.append("path")
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", "orange")
        .attr("stroke-width", 1.5)
        .attr("d", line);
    };

    fetchData();
  }, []);

  return (
    <SunlightIntensityChartBox>
      <Heading3>Sunlight Intensity (W/m²)</Heading3>
      <svg ref={chartRef} width="100%" height="100%"></svg>
    </SunlightIntensityChartBox>
  );
};

export default SunlightIntensityChart;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { db } from '../../Firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

// Styled components
const Box = styled.div`
// background: #f3d4bd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
`;

const RevenueBox = styled(Box)`
  flex: 2;
  // height: calc(50% - 20px); // Adjusted as per requirement
  max-width: fit-content;
  height: fit-content;
`;

const RevenueHeading = styled.h3`
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  margin-bottom: 15px;
  color: #3c4c4b;
  padding: 0;
`;

const RevenueValue = styled.p`
  font-size: 2.5em;
  font-weight: 300;
  margin: 0;
  color: #3c4c4b;
  padding: 0;
  padding-bottom: 10px;
`;

const RevenueDisplay = () => {
  const [revenue, setRevenue] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "farmData"), where("farm_id", "==", "FARM000"));
      const querySnapshot = await getDocs(q);
      const totalEnergyProduced = querySnapshot.docs.reduce((acc, doc) => acc + doc.data().energy_produced_kWh, 0);
      const totalRevenueGenerated = totalEnergyProduced * 0.13; // Price per kWh
      setRevenue(totalRevenueGenerated);
    };

    fetchData();
  }, []);

  return (
    <RevenueBox>
      {/* Revenue Generated: ${revenue.toFixed(2)}
       */}
      <RevenueHeading>Total Revenue Generated</RevenueHeading>
      <RevenueValue>${revenue.toFixed(2)}</RevenueValue>
    </RevenueBox>
  );
};

export default RevenueDisplay;

import React from 'react'

const SettingsH = () => {
  return (
    <div>
      SettingsH
    </div>
  )
}

export default SettingsH

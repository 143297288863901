import React from 'react';
import styled from 'styled-components';
import EnergyConsumptionChart from './EnergyConsumptionChart';
import HouseInfo from './HouseInfo';
import SpendingTracker from './SpendingTracker';
import Tips from './Tips';
import AverageDailyConsumption from './AverageDailyConsumption';
// import EnergyProductionChart from './EnergyProductionChart';
// import RevenueDisplay from './RevenueDisplay';
// import SunlightIntensityChart from './SunlightIntensityChart';
// import Notifications from './Notifications';

// Main dashboard wrapper



const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  height: 100%;
  width: 100%;
  // margin-left: 20%;
  // background: #f3d4bd;
`;

const DashBoardContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
padding: 20px;
// padding-top: 80px;
// height: 100vh;
width: 100%;
margin-left: 20%;
margin-top: 0px;
background: #F9F5F5;
font-family: 'Outfit', sans-serif;
`;




// Main Dashboard Component
const MainDash = () => (
  <DashBoardContainer>

    <HouseInfo />
    <DashboardWrapper>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 2 }}>
        <EnergyConsumptionChart />
        <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
          <SpendingTracker />
          <AverageDailyConsumption />
        </div>
      </div>
      <Tips />
    </DashboardWrapper>
    </DashBoardContainer>
);

export default MainDash;
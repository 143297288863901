import React from 'react'
import styled from 'styled-components'

const EnergyForecastContainer = styled.div`
    display: flex;
    flex-direction: row;
    `


const RevenueOpportunities = () => {
  return (
    <EnergyForecastContainer>
        RevenueOpportunities
    </EnergyForecastContainer>
  )
}

export default RevenueOpportunities

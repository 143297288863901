import React from 'react';
import styled from 'styled-components';



const DaoContainer = styled.div`
  padding: 20px;
  margin-left: 20%;
`;

const Header = styled.h1`
    font-size: 3em;
    font-weight: 300;
    color: #3c4c4b;
    margin:0;

    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;

    font-family: 'Outfit', sans-serif;


`;

const TotalValueLocked = styled.div`

    font-size: 1.4em;
    font-weight: 400;
    color: #3c4c4b;
    margin:0;

    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;

    font-family: 'Outfit', sans-serif;



`;

const ProposalsContainer = styled.div`

    font-size: 1.4em;
    font-weight: 400;
    color: #3c4c4b;
    margin:0;

    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    // margin-left: 20px;

    font-family: 'Outfit', sans-serif;

`;

const Proposal = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 400;
    border-radius: 20px;    
    padding: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    // background-color: #f3d4bd;
    border: 2px solid #3c4c4b;
    // font-family: 'Outfit', sans-serif;

`;

const Title = styled.h2`

    font-size: 1em;
    font-weight: 400;
    color: #3c4c4b;
    margin:0;
    margin-bottom: 5px;
    padding: 0;

    font-family: 'Outfit', sans-serif;

`;

const CreateProposalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 400;
    border-radius: 20px;
    padding: 25px;
    margin-top: 20px;`

const CreateProposal = styled.div`
    font-size: 1.4em;
    font-weight: 400;
    color: #3c4c4b;
    margin:0;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;

    font-family: 'Outfit', sans-serif;
    `

const Parastyle = styled.p`
    font-size: 0.9em;
    font-weight: 400;
    color: #3c4c4b;
    margin:0;
    padding: 0;
    margin-top: 20px;
    width: 80%;
    `

const CreateProposalButton = styled.button`
    background-color: #3c4c4b;
    color: white;
    padding: 10px 20px;
    max-height: 40px;
    border-radius: 10px;
    border: none;
    font-size: 0.85em;
    font-family: 'Outfit', sans-serif;
    // align-self: flex-end;

    `


const Votes = styled.p`

    font-size: 1.75em;
    font-weight: 300;
    color: #3c4c4b;
    margin:0;

    padding: 0;

    font-family: 'Outfit', sans-serif;



`;

const Button = styled.button`
    background-color: #3c4c4b;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    font-size: 0.85em;
    font-family: 'Outfit', sans-serif;
    // align-self: flex-end;
    margin:10px;
    max-height: 50px;
`;

const Flexer = styled.div`
    display: flex;
    flex-direction: row;
   justify-content: space-between;
   `;

const DraftProposal = styled.div`

    font-size: 1.4em;


    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;

    font-family: 'Outfit', sans-serif;

`;

function MainDAO() {
    const dummyData = {
        totalValueLocked: "315000 USDC",
        proposals: [
            {
                "id": 1,
                "title": "Expansion of Solar Panel Array",
                "votes": 32,
                "totalPossibleVotes": 42,
                "description": "Proposal to expand the solar farm's capacity by adding an additional 50,000 square feet of solar panels to increase our energy production and meet growing demand."
            },
            {
                "id": 2,
                "title": "Upgrade to Smart Grid Technology",
                "votes": 23,
                "totalPossibleVotes": 42,
                "description": "Proposal to integrate smart grid technology to improve energy distribution efficiency, enhance grid stability, and support two-way energy flow for increased solar adoption among community members."
            },
            {
                "id": 3,
                "title": "Community Solar Project Initiative",
                "votes": 38,
                "totalPossibleVotes": 42,
                "description": "Proposal for launching a community solar project, allowing members who cannot install solar panels on their property to benefit from solar energy, reducing overall community carbon footprint."
            },
            {
                "id": 4,
                "title": "Sustainability Education and Workshops",
                "votes": 30,
                "totalPossibleVotes": 42,
                "description": "Proposal to organize monthly sustainability workshops and educational programs to engage the community in renewable energy practices and environmental stewardship."
            },
            {
                "id": 5,
                "title": "Investment in Battery Storage Technology",
                "votes": 40,
                "totalPossibleVotes": 42,
                "description": "Proposal to invest in advanced battery storage technology to optimize energy storage from our solar panels, ensuring a reliable power supply during peak demand times and lower sunlight hours."
            }
        ],
        draftProposal: "This is a draft proposal. It has not been voted on yet."
    };

    return (
        <DaoContainer>
            <Header>DAO Dashboard</Header>
            <TotalValueLocked>Total Value Locked: {dummyData.totalValueLocked}</TotalValueLocked>
            <TotalValueLocked>Energy Attributed per share: {(1506178.10 / 7900000).toFixed(2)} kWh</TotalValueLocked>
            {/* <TotalValueLocked>Total Profit Distributed: {(195803.15 * 0.424).toFixed(0)} USDC</TotalValueLocked> */}
            
            <TotalValueLocked>Total Profit Distributed: 195803 USDC</TotalValueLocked>

            <CreateProposalContainer>
                <CreateProposal>Create a Proposal</CreateProposal>
                <CreateProposalButton>Create</CreateProposalButton>
            </CreateProposalContainer>
            <ProposalsContainer>
                {dummyData.proposals.map((proposal) => (
                    <Proposal key={proposal.id}>
                        <Title>{proposal.title}</Title>
                        <Votes>Votes: {proposal.votes}/{proposal.totalPossibleVotes}</Votes>
                        <Flexer><Parastyle>{proposal.description}</Parastyle>
                            <Button>Vote</Button></Flexer>
                    </Proposal>
                ))}
            </ProposalsContainer>
            {/* <DraftProposal>{dummyData.draftProposal}</DraftProposal> */}
        </DaoContainer>
    );
}

export default MainDAO;

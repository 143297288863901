import React from 'react';
import styled from 'styled-components';
import EnergyProductionChart from './EnergyProductionChart';
import RevenueDisplay from './RevenueDisplay';
import SunlightIntensityChart from './SunlightIntensityChart';
import Notifications from './Notifications';
import FarmInfo from './FarmInfo';
// import EnergyProductionChart from './EnergyProductionChart';
// import RevenueDisplay from './RevenueDisplay';
// import SunlightIntensityChart from './SunlightIntensityChart';
// import Notifications from './Notifications';

// Main dashboard wrapper



const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  // margin-top: 120px;
  padding: 20px;
  // height: 100%;
  width: 100%;
  // margin-left: 20%;
  // background: #f3d4bd;
`;

const DashBoardContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
padding: 20px;
// height: 100vh;
width: 100%;
margin-left: 20%;
// background: #f3d4bd;
background: #F9F5F5;
font-family: 'Outfit', sans-serif;
`;




// Main Dashboard Component
const MainDash = () => (
  <DashBoardContainer>

    <FarmInfo />
    <DashboardWrapper>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 2 }}>
        <EnergyProductionChart />
        <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
          <RevenueDisplay />
          <SunlightIntensityChart />
        </div>
      </div>
      <Notifications />
    </DashboardWrapper>
    </DashBoardContainer>
);

export default MainDash;
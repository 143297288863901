// Available Shares Component

// Display a list or grid of available ownership shares or tokens that can be purchased by investors. Each item should include:
// Price per Share/Token: Clearly display the current price in SOL or USD equivalent.
// Total Shares Available: Show how many shares are still available for purchase.
// Minimum Purchase: Indicate the minimum number of shares or tokens that can be bought in a single transaction.
// Action Buttons: Include a button for users to initiate a purchase. This should trigger a modal or form where users can specify the number of shares/tokens they wish to buy and confirm their transaction.

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import StockPriceDisplay from './StockPriceDisplay';

// Styled components
const Box = styled.div`
//   border: 2.2px solid #3c4c4b;
  max-width: fit-content;

  border-radius: 10px;
//   padding: 25px;
//   margin: 10px;
  padding-bottom: 50px;
`;

const AvailableSharesBox = styled(Box)`
    `;

const SharesHeading = styled.h3`
    font-size: 1.3em;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    margin-top: 25px;
    margin-left: 20px;
    margin-bottom: 15px;
    `;

const SharesValue = styled.p`
    font-size: 1.5em;
    font-weight: 400;
    margin: 0;
    color: #3c4c4b;
    `;

const PricePerShare = styled.p`
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
    margin-bottom: 15px;
    color: #3c4c4b;
    `;
const TotalShares = styled.p`
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
    margin-bottom: 15px;
    color: #3c4c4b;
    `;

const BuyButton = styled.button`
    background-color: #3c4c4b;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
        background-color: #2b3837;
    }
    `;
const Shares = styled.div`
// background: #f3d4bd;
// margin: 10px;
margin-right: 10px;
margin-bottom: 10px;
border-radius: 10px;
padding: 20px;
    
    `;

const SellMoreButton = styled.button`
    background-color: #f3d4bd;
    color: black;
    padding: 10px 20px;
    border: none;
    font-family: 'Outfit', sans-serif;
    margin-left: 25px;
    margin-top: 10px;
    font-weight:400;
    font-size: 1em;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
        background-color: #1dbf63;
        color: white;
    }
    `;

    const SellMoreButton2 = styled.button`
    background-color: #f3d4bd;
    color: black;
    padding: 10px 20px;
    border: none;
    font-family: 'Outfit', sans-serif;
    // margin-left: 25px;
    margin-top: 10px;
    font-weight:400;
    font-size: 1em;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
        background-color: #1dbf63;
        color: white;
    }
    `;

const SharesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    `;


const SharesRow = styled.div`
    display: flex;
    flex-direction: row;
    `;


// create a div is invisible until the user clicks on the sell more button

const SellMoreDiv = styled.div`
    display: ${props => props.sellMoreClicked ? 'flex' : 'none'};
    flex-direction: column;
    padding:25px;
    margin-top: 25px;
    `;

const TextboxInput = styled.input`
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #3c4c4b;
    margin-right: 10px;
    background: transparent;
    width: 50%;
    font-size: 1.1em;
    font-family: 'Outfit', sans-serif;
    `;

const Button = styled.button`
background-color: #f3d4bd;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    font-size: 1em;
    font-family: 'Outfit', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
        background-color: #1dbf63;
        color: white;
    }
    `;

const NumberAvaiable = styled.p`
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
    margin-bottom: 15px;
    margin-top: 15px;
    color: #3c4c4b;
    `;

const Flexer = styled.div`
    display: flex;
    flex-direction: row;
    `;


const AvailableSharesComponent = () => {
    // This is the component viewed by the owner of the solar farm. 
    // they can sell shares of the farm to investors.
    // This is where they track the shares they have sold and the shares that are still available for purchase.
    // And the value of the shares and hence the total value of the farm.
    // Also then compute and display the ratio between the wattage and the value for 100 MW solar farm.
    // Our solar farm is 100 MW.
    // We have to create dummy data for a rational scenario
    // That is useful for our component:

    const [shares, setShares] = useState([
        {
            price: 1.2,
            totalShares: 18623000,
            soldShares: 7900000,
            availableShares: 10723000

        }
    ]);

    const [value, setValue] = useState(0);
    const [wattage, setWattage] = useState(32);
    const [ratio, setRatio] = useState(0);
    const [sellMoreClicked, setSellMoreClicked] = useState(false);

    useEffect(() => {
        setValue(shares[0].price * shares[0].totalShares);
        setRatio(value / wattage);
    }
    );

    return (
        <AvailableSharesBox>
            <SharesHeading>Farm Financials</SharesHeading>
            <SharesContainer>
                <StockPriceDisplay />
                <Shares>
                    <PricePerShare>Price per Share: ${shares[0].price}</PricePerShare>
                    <TotalShares>Total Shares: {shares[0].totalShares}</TotalShares>
                    <SharesValue>Available Shares: {shares[0].availableShares}</SharesValue>
                    {/* <BuyButton>Buy</BuyButton> */}
                </Shares>
                <Shares>
                    {/* Total Valuation */}
                    <TotalShares>Wattage: {wattage} MW</TotalShares>
                    <PricePerShare>Ratio: ${ratio}/MW</PricePerShare>
                    <SharesValue>Total Valuation: ${value}</SharesValue>
                    {/* <BuyButton>Buy</BuyButton> */}
                </Shares>
            </SharesContainer>
            <SharesRow>
                <SellMoreButton
                    onClick={() => setSellMoreClicked(true)}
                >Sell More</SellMoreButton>
            </SharesRow>
            <SellMoreDiv
                sellMoreClicked={sellMoreClicked}
            >
                <Flexer>
                {/* <TextboxInput placeholder={"Enter the number of shares to sell (Max. " + {shares[0].availableShares}} /> */}
                <TextboxInput placeholder={"Number of shares (Max. " + shares[0].availableShares + ")"} />
                <Button>Sell now</Button></Flexer>
                <NumberAvaiable>
                    {shares[0].availableShares} available / {shares[0].totalShares} total
                </NumberAvaiable>
                <SharesRow>
                    <SellMoreButton2
                        onClick={() => setSellMoreClicked(false)}
                    >Cancel</SellMoreButton2>
                </SharesRow>
            </SellMoreDiv>
        </AvailableSharesBox>
    )
}

export default AvailableSharesComponent;